<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Feriados</span>
      </v-col>
      <v-col>
        <v-btn to="/feriados/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row
      ><v-col>
        <FeriadosFilter
          @feriados-changed="sendFeriados"
          :pageNumber="pageNumber"
        >
        </FeriadosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <FeriadosTable
          ref="feriadosTable"
          :feriados="this.feriados.results"
          :feriadosCount="this.feriados.count"
          @paginate="handlePagination"
        >
        </FeriadosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FeriadosTable from "../components/FeriadosTable";
import FeriadosFilter from "../components/FeriadosFilter.vue";

export default {
  name: "Feriados",
  components: {
    FeriadosTable,
    FeriadosFilter,
  },
  data() {
    return {
      feriados: [],
      pageNumber: "1",
    };
  },
  methods: {
    sendFeriados(feriados) {
      this.$refs.feriadosTable.loading = false;
      this.feriados = feriados;
    },
    handlePagination(pageNumber) {
      this.pageNumber = pageNumber;
    },
  },
};
</script>
