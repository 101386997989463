<template>
  <div v-if="feriados">
    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="feriados"
      item-key="id"
      class="elevation-1"
      locale="es-AR"
      no-data-text="No hay datos para mostrar"
      :header-props="headerProps"
      :footer-props="footerProps"
      mobile-breakpoint="500"
      @click:row="selectRow"
      disable-sort
      :server-items-length="feriadosCount"
      hide-default-footer
      :page.sync="page"
      :loading="loading"
      loading-text="Cargando... Espere por favor."
      @update:page="pageUpdateFunction"
    >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="15"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "FeriadosTable",
  data() {
    return {
      search: "",
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: "Filas por página",
      },
      itemsPerPage: 10,
      page: 1,
      loading: false,
    };
  },
  created() {
    // this.fetchItems();
  },
  props: {
    feriados: {
      type: Array,
      required: false,
    },
    feriadosCount: {
      type: Number,
      required: false,
    },
  },
  methods: {
    selectRow(feriado) {
      this.$router.push({
        name: "FeriadoDetalle",
        params: { id: feriado.id },
      });
    },

    pageUpdateFunction(newPageNumber) {
      this.loading = true;
      this.feriados = [];
      this.$emit("paginate", newPageNumber.toString());
    },
  },
  computed: {
    pageCount: function () {
      if (this.feriadosCount < 10) return 1;
      return Math.ceil(this.feriadosCount / this.itemsPerPage);
    },
    isEnabled() {
      return this.checkElementosCheckboxs();
    },

    headers() {
      return [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "fecha",
        },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "nombre",
        },

        {
          text: "Tipo",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "get_tipo_display",
        },
        {
          text: "Repite Anual",
          align: "left d-none d-md-table-cell",
          sortable: true,
          value: "repite_anual",
        },
      ];
    },
  },
};
</script>
