<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="onSubmit">
            <v-row>
              <v-col cols="6" md="2">
                <v-text-field
                  v-model="year"
                  label="Año"
                  readonly
                  append-icon="mdi-plus"
                  prepend-icon="mdi-minus"
                  @click:append="year += 1"
                  @click:prepend="year -= 1"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  :items="tiposFeriado"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo Feriado"
                  v-model="tipoFeriado"
                ></v-select>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field v-model="search" label="Texto"></v-text-field>
              </v-col>
              <v-col cols="3" md="2" class="col-btn-filter">
                <v-btn
                  icon
                  color="secondary"
                  dark
                  bottom
                  @click="doFilter(true)"
                  type="submit"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "FeriadosFilter",
  data() {
    return {
      search: "",
      feriados: [],
      year: new Date().getFullYear(),
      tipoFeriado: 0,
      tiposFeriado: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    PresentismoService.getTiposFeriado()
      .then((response) => {
        this.tiposFeriado = response.data;
        this.tiposFeriado.push({ id: 0, nombre: "Todos" });
      })
      .catch((error) => console.log(error))
      .then(this.doFilter());
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;
      PresentismoService.getFeriados(
        this.year,
        this.tipoFeriado,
        this.search,
        page
      )
        .then((response) => {
          this.feriados = response.data;
          this.$emit("feriados-changed", this.feriados);
        })
        .catch((error) => console.log(error));
    },
    onSubmit() {},
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>
